<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">{{ cardTitle }}</h4>
      <p class="category">{{ cardSubtitle }}</p>
    </md-card-header>
    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('device.deviceEventListenerForm.name')"
                    v-model="form.name"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceListenerForm.name") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div
              class="col-12"
              :class="form.listenerTypeId == 3 ? 'col-md-5' : 'col-md-3'"
            >
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="listenerTypes"
                    :label="$t('device.deviceEventListenerForm.listenerType')"
                    :rules="[rules.required]"
                    v-model="form.listenerTypeId"
                  ></v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{
                    $t("tooltips.deviceListenerForm.listenerType")
                  }}</span>
                </v-tooltip>
              </div>
            </div>
            <div
              class="col-md-5 col-12"
              v-if="
                form.listenerTypeId == 2 ||
                form.listenerTypeId == 4 ||
                form.listenerTypeId == 1
              "
            >
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <TGCTextField
                        :label="$t('device.deviceEventListenerForm.reference')"
                        v-model="form.listenerReference"
                        :rules="[
                          rules.required,
                          (value) => rules.minLength(value, 0),
                          (value) => rules.maxLength(value, 100),
                        ]"
                      />
                    </div>
                    <div class="col-md-6 col-12">
                      <md-button
                        @click="getListenerReference"
                        class="md-raised"
                        :class="[`md-${getTheme}`]"
                      >
                        {{ $t("buttons.reference") }}
                      </md-button>
                    </div>
                  </div>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceListenerForm.reference") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-md-3 col-12" v-if="form.listenerTypeId == 3">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('device.deviceEventListenerForm.timeout')"
                    v-model="form.timeout"
                    :rules="[
                      rules.required,
                      (value) => rules.min(value, 0),
                      (value) => rules.max(value, 100),
                    ]"
                    type="number"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.deviceListenerForm.timeout") }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    :label="$t('device.deviceEventListenerForm.description')"
                    v-model="form.description"
                  ></v-textarea>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{
                    $t("tooltips.deviceListenerForm.description")
                  }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div class="row">
            <notification-channel-listeners
              :selectedUsers="form.selectedUsers"
              :selectedChannels="form.selectedChannels"
              @users="setUsers"
              @channels="setChannels"
            />
          </div>
        </v-form>
        <div class="row">
          <div class="col-12 text-right">
            <tgl-save-button
              ref="saveBtn"
              :entity="entity"
              :dispatch="entity.dispatch"
              :action="editMode ? 'editado' : 'creado'"
              :redirect="entity.redirect"
              :validateForm="handleSubmit"
            ></tgl-save-button>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import form from "../../mixins/form";
import NotificationChannelListeners from "./NotificationChannelListeners";
import TGCTextField from "@/components/UI/TGCTextField";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";
import { mapGetters } from "vuex";

export default {
  name: "device-listener-form",
  components: {
    NotificationChannelListeners,
    TglSaveButton,
    TGCTextField,
  },
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "ow",
    },
  },
  data: () => ({
    form: {
      id: 0,
      name: "",
      listenerTypeId: 1,
      listenerReference: "",
      isActive: true,
      selectedUsers: [],
      selectedChannels: [],
      description: "",
      timeout: 0,
    },
    editMode: false,
    listenerTypes: [],
  }),
  watch: {
    user() {
      this.initCreateItemMode();
    },
  },
  mounted() {
    if (this.$route.params.eventListener) {
      this.editMode = true;
      this.getItem();
    } else {
      this.initCreateItemMode();
      this.form.listenerTypeId = 1;
    }
    this.getListenerTypes();
  },
  computed: {
    ...mapGetters(["getTheme"]),
    cardTitle() {
      return this.editMode
        ? this.$t("device.deviceEventListenerForm.titleFormEdit")
        : this.$t("device.deviceEventListenerForm.titleFormCreate");
    },
    user() {
      return this.$store.getters["user/user"];
    },
    entity() {
      let params = this.formatItem();
      return {
        name: "event listener",
        params: params,
        dispatch: this.editMode
          ? "listener/updateDeviceListener"
          : "listener/storeDeviceListener",
        redirect: {
          name: "deviceEventListeners",
          params: { device: this.$route.params.device },
        },
      };
    },
  },
  methods: {
    initCreateItemMode() {
      if (!this.editMode && this.user) this.form.selectedUsers = [this.user.id];
    },
    getListenerTypes() {
      this.$store
        .dispatch("listener/getDeviceListenerTypes")
        .then((listenerTypes) => (this.listenerTypes = listenerTypes));
    },
    handleSubmit() {
      return this.$refs.form.validate();
    },
    getItem() {
      this.$store
        .dispatch("listener/getDeviceListener", {
          device: this.$route.params.device,
          listener: this.$route.params.eventListener,
        })
        .then((eventListener) => {
          this.setItem(eventListener);
        });
    },
    formatItem() {
      const {
        name,
        listenerTypeId,
        selectedUsers,
        selectedChannels,
        listenerReference,
        isActive,
        description,
        timeout,
      } = this.form;
      const { device } = this.$route.params;
      let listener;
      if (this.form.listenerTypeId == 2 || this.form.listenerTypeId == 4) {
        listener = {
          name,
          listenerTypeId,
          isActive,
          selectedUsers,
          listenerReference,
          description,
          selectedChannels,
          deviceId: device,
        };
      }
      if (this.form.listenerTypeId == 3) {
        listener = {
          name,
          listenerTypeId,
          isActive,
          selectedUsers,
          timeout,
          description,
          selectedChannels,
          deviceId: device,
        };
      }
      if (this.editMode) listener.id = this.form.id;
      return {
        listener,
        device,
      };
    },
    setItem(item) {
      this.form.id = item.id;
      this.form.name = item.name;
      this.form.listenerReference = item.listener_reference;
      this.form.description = item.description;
      this.form.timeout = item.timeout;
      this.form.listenerTypeId = item.listenerTypeId;
      this.form.selectedUsers = item.selectedUsers;
      this.form.selectedChannels = item.selectedChannels;
    },
    setUsers(users) {
      this.form.selectedUsers = users;
    },
    setChannels(channels) {
      this.form.selectedChannels = channels;
    },
    getListenerReference() {
      this.$store
        .dispatch("listener/getListenerReference", {
          device: this.$route.params.device,
          listenerName: this.form.name,
        })
        .then((data) => {
          this.form.listenerReference = data.listenerReference;
        });
    },
  },
};
</script>