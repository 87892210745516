var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-card',[_c('md-card-header',{attrs:{"data-background-color":_vm.getTheme}},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.cardTitle))]),_c('p',{staticClass:"category"},[_vm._v(_vm._s(_vm.cardSubtitle))])]),_c('md-card-content',[_c('div',{staticClass:"container-fluid"},[_c('v-form',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-12"},[_c('div',{staticClass:"row d-flex align-center m-0 mb-5"},[_c('div',{staticClass:"col col-10"},[_c('TGCTextField',{attrs:{"label":_vm.$t('device.deviceEventListenerForm.name'),"rules":[
                    _vm.rules.required,
                    (value) => _vm.rules.minLength(value, 0),
                    (value) => _vm.rules.maxLength(value, 100),
                  ]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.deviceListenerForm.name")))])])],1)]),_c('div',{staticClass:"col-12",class:_vm.form.listenerTypeId == 3 ? 'col-md-5' : 'col-md-3'},[_c('div',{staticClass:"row d-flex align-center m-0 mb-5"},[_c('div',{staticClass:"col col-10"},[_c('v-select',{attrs:{"item-value":"id","item-text":"name","items":_vm.listenerTypes,"label":_vm.$t('device.deviceEventListenerForm.listenerType'),"rules":[_vm.rules.required]},model:{value:(_vm.form.listenerTypeId),callback:function ($$v) {_vm.$set(_vm.form, "listenerTypeId", $$v)},expression:"form.listenerTypeId"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.deviceListenerForm.listenerType")))])])],1)]),(
              _vm.form.listenerTypeId == 2 ||
              _vm.form.listenerTypeId == 4 ||
              _vm.form.listenerTypeId == 1
            )?_c('div',{staticClass:"col-md-5 col-12"},[_c('div',{staticClass:"row d-flex align-center m-0 mb-5"},[_c('div',{staticClass:"col col-10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12"},[_c('TGCTextField',{attrs:{"label":_vm.$t('device.deviceEventListenerForm.reference'),"rules":[
                        _vm.rules.required,
                        (value) => _vm.rules.minLength(value, 0),
                        (value) => _vm.rules.maxLength(value, 100),
                      ]},model:{value:(_vm.form.listenerReference),callback:function ($$v) {_vm.$set(_vm.form, "listenerReference", $$v)},expression:"form.listenerReference"}})],1),_c('div',{staticClass:"col-md-6 col-12"},[_c('md-button',{staticClass:"md-raised",class:[`md-${_vm.getTheme}`],on:{"click":_vm.getListenerReference}},[_vm._v(" "+_vm._s(_vm.$t("buttons.reference"))+" ")])],1)])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}],null,false,1859125418)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.deviceListenerForm.reference")))])])],1)]):_vm._e(),(_vm.form.listenerTypeId == 3)?_c('div',{staticClass:"col-md-3 col-12"},[_c('div',{staticClass:"row d-flex align-center m-0 mb-5"},[_c('div',{staticClass:"col col-10"},[_c('TGCTextField',{attrs:{"label":_vm.$t('device.deviceEventListenerForm.timeout'),"rules":[
                    _vm.rules.required,
                    (value) => _vm.rules.min(value, 0),
                    (value) => _vm.rules.max(value, 100),
                  ],"type":"number"},model:{value:(_vm.form.timeout),callback:function ($$v) {_vm.$set(_vm.form, "timeout", $$v)},expression:"form.timeout"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}],null,false,1859125418)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.deviceListenerForm.timeout")))])])],1)]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-12"},[_c('div',{staticClass:"row d-flex align-center m-0 mb-5"},[_c('div',{staticClass:"col col-10"},[_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":_vm.$t('device.deviceEventListenerForm.description')},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"col col-2",attrs:{"color":"primary","size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.deviceListenerForm.description")))])])],1)])]),_c('div',{staticClass:"row"},[_c('notification-channel-listeners',{attrs:{"selectedUsers":_vm.form.selectedUsers,"selectedChannels":_vm.form.selectedChannels},on:{"users":_vm.setUsers,"channels":_vm.setChannels}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-right"},[_c('tgl-save-button',{ref:"saveBtn",attrs:{"entity":_vm.entity,"dispatch":_vm.entity.dispatch,"action":_vm.editMode ? 'editado' : 'creado',"redirect":_vm.entity.redirect,"validateForm":_vm.handleSubmit}})],1)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }