<template>
  <div class="entity-actions">
    <tgl-delete-button
      dispatch="listener/deleteDeviceListener"
      :redirect="{
        name: 'deviceEventListeners',
        params: { device: $route.params.device },
      }"
    />
  </div>
</template>

<script>
import TglDeleteButton from "../UI/TglDeleteButton";
export default {
  components: {
    TglDeleteButton,
  },
};
</script>