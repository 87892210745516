<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 pa-0 ma-0 tgl-page-header">
          <tgl-breadcrumbs :items="breadcrumbs" />
          <device-listener-actions v-if="$route.params.eventListener" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 pa-0 ma-0">
          <device-listener-form />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TglBreadcrumbs from "../../components/UI/TglBreadcrumbs";
import DeviceListenerActions from "../../components/EventListeners/DeviceActions";
import DeviceListenerForm from "../../components/EventListeners/DeviceListenerForm";

export default {
  components: {
    TglBreadcrumbs,
    DeviceListenerActions,
    DeviceListenerForm,
  },
  computed: {
    breadcrumbs() {
      let breadcrumbs = [
        {
          label: "Dispositivos",
          disabled: false,
          to: { name: "devices" },
        },
        {
          label: this.$route.params.device,
          disabled: false,
          to: {
            name: "deviceDetails",
            params: { device: this.$route.params.device },
          },
        },
        {
          label: "Observadores de eventos",
          disabled: false,
          to: {
            name: "deviceEventListeners",
            params: {
              device: this.$route.params.device,
            },
          },
        },
      ];
      if (this.$route.name === "deviceEventListenerEdit") {
        breadcrumbs.push({
          label: this.$route.params.eventListener,
          disabled: true,
          to: { name: "deviceEventListenerEdit", params: this.$route.params },
        });
      }
      breadcrumbs.push({
        label: this.$t(this.$route.meta.title.toLowerCase()),
        disabled: true,
        to: { name: "deviceEventListenerCreate" },
      });
      return breadcrumbs;
    },
  },
};
</script>